import React, { useEffect, useState } from "react";
import { Button, makeStyles, shorthands, tokens } from "@fluentui/react-components";
import { Add20 } from "../shared/BundledIcons";
import { AddWorkflowDialog } from "./AddWorkflowDialog";
import { IUserWorkflow } from "../../libs/models/Workflows/UserWorkflow";
import { AddWorkflowPage } from "./AddWorkflowPage";
import { IUserWorkflowInstance } from "../../libs/models/Workflows/UserWorkflowInstance";
import { useWorkflow } from "../../libs/hooks/useWorkflow";
import WorkflowsTable from "./WorkflowsTable";
import { ViewWorkflowPage } from "./ViewWorkflowPage";
import { HomeRegular, ArrowClockwise20Filled } from "@fluentui/react-icons";

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        backgroundColor: tokens.colorNeutralBackground3,
        boxShadow: 'rgb(0 0 0 / 25%) 0 0.2rem 0.4rem -0.075rem',
    },
    header: {
        ...shorthands.borderBottom('1px', 'solid', 'rgb(0 0 0 / 10%)'),
        ...shorthands.padding(tokens.spacingVerticalS, tokens.spacingHorizontalM),
        backgroundColor: tokens.colorNeutralBackground4,
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'border-box',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        ...shorthands.padding(tokens.spacingVerticalM, tokens.spacingHorizontalM),
        fontSize: tokens.fontSizeBase500,
        fontWeight: tokens.fontWeightSemibold,
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
    },
    body: {
        ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL),
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...shorthands.padding(0, 0, tokens.spacingVerticalL, tokens.spacingHorizontalXL),
    },
    addButton: {
        marginRight: tokens.spacingHorizontalM,
    },
});

export enum WorkflowAppState {
    HomePage,
    AddWorkflowPage,
    ViewWorkflowPage,
}

export const Workflows: React.FC = () => {
    const styles = useStyles();
    const workflowHook = useWorkflow();
    const [workflowAppState, setWorkflowAppState] = React.useState(WorkflowAppState.HomePage);
    const [isAddWorkflowDialogOpen, setIsAddWorkflowDialogOpen] = useState(false);
    const [userWorkflowToAdd, setUserWorkflowToAdd] = useState<IUserWorkflow | undefined>(undefined);
    const [workflows, setWorkflows] = useState<IUserWorkflowInstance[]>([]);
    const [selectedWorkflow, setSelectedWorkflow] = useState<IUserWorkflowInstance | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        workflowHook.getWorkflowInstancesByUser()
            .then((workflows) => {
                setWorkflows(workflows);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error getting workflows:', error);
                setIsLoading(false);
            });
    }, [refresh]);

    const openAddWorkflowPage = (workflow: IUserWorkflow | undefined) => {
        setUserWorkflowToAdd(workflow);
        setWorkflowAppState(WorkflowAppState.AddWorkflowPage);
    };

    const openAddWorkflowDialog = () => {
        setIsAddWorkflowDialogOpen(true);
    };

    const closeAddWorkflowDialog = () => {
        setIsAddWorkflowDialogOpen(false);
    };

    const handleWorkflowClick = (workflow: IUserWorkflowInstance) => {
        setSelectedWorkflow(workflow);
        setWorkflowAppState(WorkflowAppState.ViewWorkflowPage);
    };

    const backToHomePage = () => {
        setWorkflowAppState(WorkflowAppState.HomePage);
        setRefresh(!refresh);
    }

    const handleRefresh = () => {
        setRefresh(!refresh);
    }

    return (
        <div className={styles.root}>
            <div className={styles.header}>
                <div className={styles.title}>
                    Workflows
                </div>
                {workflowAppState !== WorkflowAppState.HomePage && (
                    <Button
                        icon={<HomeRegular/>}
                        onClick={() => { setWorkflowAppState(WorkflowAppState.HomePage); }}
                    >
                        Home
                    </Button>
                )}
            </div>
            <div className={styles.body}>
                {workflowAppState === WorkflowAppState.HomePage && (
                    <>
                        <div className={styles.buttonContainer}>
                            <Button
                                icon={<Add20 />}
                                onClick={openAddWorkflowDialog}
                                className={styles.addButton}
                            >
                                Add Workflow
                            </Button>
                            <Button
                                icon={<ArrowClockwise20Filled />}
                                onClick={handleRefresh}
                            />
                        </div>
                        <WorkflowsTable workflows={workflows} onWorkflowClick={handleWorkflowClick} isLoading={isLoading} />
                    </>
                )}
                {workflowAppState === WorkflowAppState.AddWorkflowPage && <AddWorkflowPage workflow={userWorkflowToAdd} onSubmit={backToHomePage} />}
                {workflowAppState === WorkflowAppState.ViewWorkflowPage && selectedWorkflow && (
                    <ViewWorkflowPage workflowInstance={selectedWorkflow} />
                )}
            </div>
            <AddWorkflowDialog open={isAddWorkflowDialogOpen} onCloseDialog={closeAddWorkflowDialog} openAddWorkflowPage={openAddWorkflowPage} />
        </div>
    );
};
