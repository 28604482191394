import React, { useCallback, useState } from 'react';
import {
    makeStyles,
    shorthands,
    tokens,
    Text,
    Button,
    Card,
    CardHeader,
    Dialog,
    DialogTrigger,
    DialogSurface,
    DialogBody,
    DialogTitle,
    DialogContent,
    Combobox,
    DialogActions,
    ComboboxProps, Option
} from "@fluentui/react-components";
import { EyeRegular, AddRegular, DocumentRegular, Dismiss12Regular } from "@fluentui/react-icons";
import { IUserWorkflowInstance, WorkflowInstanceState } from "../../libs/models/Workflows/UserWorkflowInstance";
import { ViewDocumentsPage } from './ViewDocumentsPage';
import ViewOutputsPage from './ViewOutputsPage';
import { ExportOutputs } from './ExportOutputs';
import { IGraphChatUser } from '../../libs/models/GraphChatUser';
import { useChat } from '../../libs/hooks';
import { debounce } from 'lodash';
import { IChangeUsersRequestUser } from '../../libs/models/Workflows/ChangeUsersRequest';
import { useWorkflow } from '../../libs/hooks/useWorkflow';
import { useCustomToast } from '../../libs/hooks/useToast';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('24px'),
        ...shorthands.margin(0, 'auto'),
        width: '100%',
        overflowX: 'hidden',
        paddingBottom: '32px',
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('0', '16px', '32px', '16px'),
        maxWidth: '1400px',
        width: '100%',
        ...shorthands.margin('0', 'auto'),
        boxSizing: 'border-box',
    },
    header: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...shorthands.gap('16px'),
        marginBottom: '24px',
        '@media (min-width: 768px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
        },
    },
    leftSection: {
        display: 'flex',
        flexDirection: 'column',
    },
    rightSection: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        ...shorthands.gap('8px'),
        '@media (min-width: 768px)': {
            alignItems: 'flex-end',
        },
    },
    buttonGroup: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('8px'),
        '@media (min-width: 480px)': {
            flexDirection: 'row',
            ...shorthands.gap('12px'),
        },
    },
    columns: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('24px'),
        '@media (min-width: 992px)': {
            flexDirection: 'row',
        },
    },
    column: {
        flexGrow: 1,
        width: '100%',
        '@media (min-width: 992px)': {
            maxWidth: 'calc(50% - 12px)',
        },
    },
    card: {
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        boxShadow: tokens.shadow4,
        marginBottom: '16px',
    },
    cardContent: {
        ...shorthands.padding('0', '16px'),
    },
    infoItem: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.padding('8px', '0'),
        '@media (min-width: 480px)': {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
    },
    infoKey: {
        fontWeight: tokens.fontWeightSemibold,
        marginRight: '16px',
    },
    infoValue: {
        wordBreak: 'break-word',
        '@media (min-width: 480px)': {
            textAlign: 'right',
        },
    },
    cardHeader: {
        backgroundColor: tokens.colorNeutralBackground1,
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
        borderBottomColor: tokens.colorNeutralStroke1,
        ...shorthands.padding('0px', '16px', '8px', '16px'),
    },
    headerText: {
        ...shorthands.padding('8px', '0'),
    },
    cardHeaderContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        ...shorthands.gap('16px'),
    },
    combobox: {
        width: '100%',
    },
    tagsList: {
        listStyleType: "none",
        marginBottom: tokens.spacingVerticalXXS,
        marginTop: 0,
        paddingLeft: 0,
        display: "flex",
        flexWrap: "wrap",
        gridGap: tokens.spacingHorizontalXXS,
    },
    selectedUserButton: {
        backgroundColor: tokens.colorNeutralBackground1,
        color: tokens.colorNeutralForeground1,
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        ...shorthands.border('1px', 'solid', tokens.colorNeutralStroke1),
        ...shorthands.padding(tokens.spacingVerticalXS, tokens.spacingHorizontalS),
        display: 'flex',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingHorizontalXS),
    },
});

/* eslint-disable */
const debounceSearchUsers = debounce((searchTerm: string, chat: any, setSearchResults: (results: IGraphChatUser[]) => void) => {
    chat.searchUsers(searchTerm).then((results: IGraphChatUser[]) => {
        setSearchResults(results);
    }).catch((error: any) => {
        console.error('Error searching users:', error);
    });
}, 300);

/* eslint-enable */

interface ViewWorkflowPageProps {
    workflowInstance: IUserWorkflowInstance;
}

export const ViewWorkflowPage: React.FC<ViewWorkflowPageProps> = ({workflowInstance}) => {
    const styles = useStyles();
    const [currentView, setCurrentView] = useState<'workflow' | 'documents' | 'outputs'>('workflow');
    const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
    const [searchResults, setSearchResults] = useState<IGraphChatUser[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [selectedUsers, setSelectedUsers] = useState<IGraphChatUser[]>([]);
    const chat = useChat();
    const workflows = useWorkflow();
    const { showToast } = useCustomToast();

    const handleAddUser = () => {
        console.log("Add user");
    };

    const handleViewDocuments = () => {
        setCurrentView('documents');
    };

    const handleViewOutputs = () => {
        setCurrentView('outputs');
    };

    const handleBackToWorkflow = () => {
        setCurrentView('workflow');
    };

    const handleSearch = useCallback((searchTerm: string) => {
        setSearchTerm(searchTerm);
        if (searchTerm.trim()) {
            debounceSearchUsers(searchTerm.trim(), chat, setSearchResults);
        }
    }, [chat]);

    const onSelect: ComboboxProps["onOptionSelect"] = (_event, data) => {
        const foundUser = searchResults.find((user) => user.id === data.optionValue);
        if (foundUser) {
            setSelectedUsers(prev =>
                prev.some(user => user.id === foundUser.id)
                    ? prev
                    : [...prev, foundUser]
            );
            setSearchTerm('');
            setSearchResults([]);
        }
    };

    const handleRemoveUser = (userId: string) => {
        setSelectedUsers(prev => prev.filter(user => user.id !== userId));
    };

    const handleConfirmAddUsers = () => {
        const users: IChangeUsersRequestUser[] = [
            ...workflowInstance.userWorkflowInstanceUsers.map(user => ({userId: user.userId, isOwner: false})),
            ...selectedUsers.map(user => ({userId: user.id, isOwner: false}))
        ]
        workflows.updateUsers(workflowInstance.workflowInstanceId, users)
            .then(() => {
                showToast('Users added successfully', [], 'success');
            })
            .catch((_error) => {
                showToast('Error adding users', [], 'error');
            })
            .finally(() => {
                setIsAddUserDialogOpen(false);
                setSelectedUsers([]);
            });
    };

    const renderWorkflowView = () => (
        <div className={styles.body}>
            <div className={styles.header}>
                <div className={styles.leftSection}>
                    <Text size={600} weight="semibold" className={styles.headerText}>{workflowInstance.instanceName}</Text>
                    <Text size={500} weight="regular" className={styles.headerText}>{workflowInstance.name}</Text>
                    <Text size={300}>State: {WorkflowInstanceState[workflowInstance.workflowInstanceState]}</Text>
                </div>
                <div className={styles.rightSection}>
                    <div className={styles.buttonGroup}>
                        <ExportOutputs workflowInstanceId={workflowInstance.workflowInstanceId}/>
                        <Button icon={<EyeRegular/>} onClick={handleViewOutputs}>View Outputs</Button>
                        <Button icon={<DocumentRegular/>} onClick={handleViewDocuments}>View Documents</Button>
                    </div>
                    <Text size={200}>Version: {workflowInstance.workflowVersion}</Text>
                </div>
            </div>

            <div className={styles.columns}>
                <Card className={styles.column}>
                    <CardHeader
                        header={<Text weight="semibold" size={500}>User Input</Text>}
                        className={styles.cardHeader}
                    />
                    <div className={styles.cardContent}>
                        {Object.entries(workflowInstance.userInput).map(([key, value]) => (
                            <div key={key} className={styles.infoItem}>
                                <Text className={styles.infoKey}>{key}:</Text>
                                <Text className={styles.infoValue}>{value}</Text>
                            </div>
                        ))}
                    </div>
                </Card>

                <Card className={styles.column}>
                    <CardHeader
                        header={
                            <div className={styles.cardHeaderContent}>
                                <Text weight="semibold" size={500}>Workflow Users</Text>
                                <Dialog open={isAddUserDialogOpen} onOpenChange={(_, data) => {
                                    setIsAddUserDialogOpen(data.open)
                                }}>
                                    <DialogTrigger disableButtonEnhancement>
                                        <Button icon={<AddRegular/>} onClick={handleAddUser}>Add Users</Button>
                                    </DialogTrigger>
                                    <DialogSurface>
                                        <DialogBody>
                                            <DialogTitle>Add Users to Workflow</DialogTitle>
                                            <DialogContent className={styles.dialogContent}>
                                                <ul className={styles.tagsList}>
                                                    {selectedUsers.map((user) => (
                                                        <li key={user.id}>
                                                            <Button
                                                                size="small"
                                                                className={styles.selectedUserButton}
                                                                icon={<Dismiss12Regular/>}
                                                                iconPosition="after"
                                                                onClick={() => {
                                                                    handleRemoveUser(user.id)
                                                                }}
                                                            >
                                                                {user.name}
                                                            </Button>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <Combobox
                                                    className={styles.combobox}
                                                    placeholder="Search for users to invite"
                                                    onInput={(event) => {
                                                        handleSearch(event.currentTarget.value)
                                                    }}
                                                    value={searchTerm}
                                                    onOptionSelect={onSelect}
                                                >
                                                    {searchResults.map((user) => (
                                                        <Option key={user.id} value={user.id}>
                                                            {user.name}
                                                        </Option>
                                                    ))}
                                                </Combobox>
                                            </DialogContent>
                                            <DialogActions>
                                                <DialogTrigger disableButtonEnhancement>
                                                    <Button appearance="secondary">Cancel</Button>
                                                </DialogTrigger>
                                                <Button appearance="primary" onClick={handleConfirmAddUsers}>Add Users</Button>
                                            </DialogActions>
                                        </DialogBody>
                                    </DialogSurface>
                                </Dialog>
                            </div>
                        }
                        className={styles.cardHeader}
                    />
                    <div className={styles.cardContent}>
                        {workflowInstance.userWorkflowInstanceUsers.map((user, index) => (
                            <div key={index} className={styles.infoItem}>
                                <Text className={styles.infoKey}>{user.displayName}</Text>
                                <Text className={styles.infoValue}>{user.email}</Text>
                            </div>
                        ))}
                    </div>
                </Card>
            </div>
        </div>
    );

    return (
        <div className={styles.root}>
            {currentView === 'workflow' ? (
                renderWorkflowView()
            ) : currentView === 'documents' ? (
                <ViewDocumentsPage
                    workflowInstanceId={workflowInstance.workflowInstanceId}
                    onBack={handleBackToWorkflow}
                />
            ) : (
                <ViewOutputsPage
                    workflowInstanceId={workflowInstance.workflowInstanceId}
                    onBack={handleBackToWorkflow}
                />
            )}
        </div>
    );
};
