import React, { useMemo, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableColumnDefinition,
    TableHeader,
    TableHeaderCell,
    TableRow,
    createTableColumn,
    makeStyles,
    shorthands,
    tokens,
    Spinner,
} from "@fluentui/react-components";
import { IUserWorkflowInstance, WorkflowInstanceState } from "../../libs/models/Workflows/UserWorkflowInstance";

interface WorkflowsTableProps {
    workflows: IUserWorkflowInstance[];
    onWorkflowClick: (workflow: IUserWorkflowInstance) => void;
    isLoading: boolean;
}

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 200px)',
        ...shorthands.overflow('hidden'),
    },
    tableContainer: {
        flexGrow: 1,
        overflowY: 'auto',
        ...shorthands.padding('0', '20px'),
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorNeutralStroke1,
            ...shorthands.borderRadius('4px'),
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: tokens.colorNeutralStroke1Hover,
        },
    },
    table: {
        backgroundColor: tokens.colorNeutralBackground1,
    },
    tableHeader: {
        fontWeight: tokens.fontWeightSemibold,
    },
    clickableRow: {
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: tokens.colorNeutralBackground3,
        },
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
    },
});

const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    };
    return date.toLocaleDateString('en-GB', options);
};

type SortDirection = 'ascending' | 'descending';

const WorkflowsTable: React.FC<WorkflowsTableProps> = ({ workflows, onWorkflowClick, isLoading }) => {
    const styles = useStyles();
    const [sortColumn, setSortColumn] = useState<keyof IUserWorkflowInstance>('instanceName');
    const [sortDirection, setSortDirection] = useState<SortDirection>('ascending');

    const handleSort = (columnId: keyof IUserWorkflowInstance) => {
        if (columnId === sortColumn) {
            setSortDirection(sortDirection === 'ascending' ? 'descending' : 'ascending');
        } else {
            setSortColumn(columnId);
            setSortDirection('ascending');
        }
    };

    const columns: Array<TableColumnDefinition<IUserWorkflowInstance>> = [
        createTableColumn<IUserWorkflowInstance>({
            columnId: 'instanceName',
            compare: (a, b) => a.instanceName.localeCompare(b.instanceName),
            renderHeaderCell: () => (
                <TableHeaderCell
                    key="instanceName"
                    onClick={() => { handleSort('instanceName'); }}
                    sortDirection={sortColumn === 'instanceName' ? sortDirection : undefined}
                    className={styles.tableHeader}
                >
                    Workflow Name
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.workflowInstanceId}-instanceName`}>
                    <TableCellLayout truncate>
                        {item.instanceName}
                    </TableCellLayout>
                </TableCell>
            ),
        }),
        createTableColumn<IUserWorkflowInstance>({
            columnId: 'name',
            compare: (a, b) => a.name.localeCompare(b.name),
            renderHeaderCell: () => (
                <TableHeaderCell
                    key="name"
                    onClick={() => { handleSort('name'); }}
                    sortDirection={sortColumn === 'name' ? sortDirection : undefined}
                    className={styles.tableHeader}
                >
                    Workflow Type
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.workflowInstanceId}-name`}>
                    <TableCellLayout truncate>
                        {item.name}
                    </TableCellLayout>
                </TableCell>
            ),
        }),
        createTableColumn<IUserWorkflowInstance>({
            columnId: 'workflowInstanceState',
            compare: (a, b) => a.workflowInstanceState - b.workflowInstanceState,
            renderHeaderCell: () => (
                <TableHeaderCell
                    key="state"
                    onClick={() => { handleSort('workflowInstanceState'); }}
                    sortDirection={sortColumn === 'workflowInstanceState' ? sortDirection : undefined}
                    className={styles.tableHeader}
                >
                    State
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.workflowInstanceId}-state`}>
                    <TableCellLayout truncate>
                        {WorkflowInstanceState[item.workflowInstanceState]}
                    </TableCellLayout>
                </TableCell>
            ),
        }),
        createTableColumn<IUserWorkflowInstance>({
            columnId: 'workflowInstanceCreated',
            compare: (a, b) => new Date(a.workflowInstanceCreated).getTime() - new Date(b.workflowInstanceCreated).getTime(),
            renderHeaderCell: () => (
                <TableHeaderCell
                    key="dateCreated"
                    onClick={() => { handleSort('workflowInstanceCreated'); }}
                    sortDirection={sortColumn === 'workflowInstanceCreated' ? sortDirection : undefined}
                    className={styles.tableHeader}
                >
                    Date Created
                </TableHeaderCell>
            ),
            renderCell: (item) => (
                <TableCell key={`${item.workflowInstanceId}-workflowInstanceCreated`}>
                    <TableCellLayout truncate>
                        {formatDate(item.workflowInstanceCreated)}
                    </TableCellLayout>
                </TableCell>
            ),
        }),
    ];

    const sortedWorkflows = useMemo(() => {
        return [...workflows].sort((a, b) => {
            const column = columns.find(col => col.columnId === sortColumn);
            if (column?.compare) {
                const result = column.compare(a, b);
                return sortDirection === 'ascending' ? result : -result;
            }
            return 0;
        });
    }, [workflows, sortColumn, sortDirection]);

    if (isLoading) {
        return (
            <div className={styles.spinnerContainer}>
                <Spinner size="large" label="Loading workflows..." />
            </div>
        );
    }

    return (
        <div className={styles.root}>
            <div className={styles.tableContainer}>
                <Table aria-label="Workflows table" className={styles.table}>
                    <TableHeader>
                        <TableRow>{columns.map((column) => column.renderHeaderCell())}</TableRow>
                    </TableHeader>
                    <TableBody>
                        {sortedWorkflows.map((item) => (
                            <TableRow
                                key={item.workflowInstanceId}
                                className={styles.clickableRow}
                                onClick={() => { onWorkflowClick(item); }}
                            >
                                {columns.map((column) => column.renderCell(item))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </div>
    );
};

export default WorkflowsTable;
